import React, {useState, useRef, useEffect, useContext} from 'react'
import Layout from '../Layout/Layout'
import { Functions } from "../functions/functions";
import HeaderContext from '../contexts/HeaderContext';
import Lenis from 'lenis'
import 'lenis/dist/lenis.css'
import Preloader from '../Layout/Preloader';

import {Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/css';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Header from '../Layout/Header';
import { Link } from 'react-router-dom';
import { Mousewheel } from 'swiper/modules';


gsap.registerPlugin(ScrollTrigger)

const ProjectSlide = ({project}) => (
  <div className="slide-wrapper">
   {project.mediaCollection && project.mediaCollection.items[0].contentType.includes('image') && (
  <img data-speed="auto" src={project.mediaCollection.items[0].url} />
  )}
{project.mediaCollection && project.mediaCollection.items[0].contentType.includes('video') && (

<div className='video-container'>
<video autoPlay muted loop data-speed="auto" src={project.mediaCollection.items[0].url} />
</div>

)}
</div>
)


const ProductSpec = ({ content, opened, setOpened, index }) => {
  return (
    <ProjectSlide project={content} />
  );
};


const ProductSpecClosed = ({content, opened, setOpened, index}) => {
  const [openedHeight, setopenedHeight] = useState(0);
  const {screen: {width, height}} = useContext(HeaderContext);
  useEffect(() => {
    if (opened === index){
      const element = document.querySelector(`[data-content="${content.slug}"]`);
      console.log(element);
      setopenedHeight(element.offsetHeight);
    }else{
      setopenedHeight(0);
    }
  }, [opened, width])
  
 return (
      <div className='product__spec_closed'>
          <div className='product_spec__wrapper_closed'>
          <div className='product__spec_title'>
              <button className='product__spec_button' onClick={()=>{
                if (opened === index) setOpened(null)
                else setOpened(index)
              }} aria-expanded={opened === index} >
              <span>{content.title}</span>
              <span class="Collapsible__Plus"></span>
              </button>
          </div>
      </div>
      <div style={{height: `${openedHeight}px`}} aria-expanded={opened === index} className='product__spec_content'>
          <div aria-expanded={opened === index} data-content={content.slug} className='product_spec_content--container'>
            <div className='content-work'>
                <ProjectSlide project={content} />
            </div>
          </div>
      </div>
      </div>

  )
}

const SkillElement = ({skill}) => (
  <div className='skill_element'>
    {skill.title}
  </div>
)


export default function INDEX_PAGE() {

  const [projects, setProjects] = useState([]);
  const [skills, setSkills] = useState([]);
  const [opened, setOpened] = useState(null);


  useEffect(() => {
    (async()=>{
      console.log(process.env)
      const i =  await Functions.projects();
      const s =  await Functions.skills();
      console.log(s);
      setProjects(i)
      setSkills(s)
    })();
  }, [])

  const boxRef = useRef(null);
  const sectionRef = useRef(null);
  const workRef = useRef(null);
  const servicesRef = useRef(null);
  const {preloaderLoaded, title} = useContext(HeaderContext);

  useEffect(() => {
    title.set("Gabrisp");

    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      smoothTouch: true, // Enable smooth scrolling on touch devices
    });

    lenis.scrollTo(0, {
      duration: 0, // Adjust scroll duration
      immediate: true, // If true, scrolls instantly without smoothness
    });

    const animateScroll = (time) => {
      lenis.raf(time);
      requestAnimationFrame(animateScroll);
    };

    requestAnimationFrame(animateScroll);

    lenis.on('scroll', ScrollTrigger.update);


    if (boxRef.current && sectionRef.current) {


      gsap.fromTo(
        boxRef.current, 
        { y: '0' },
        {
          y: '-10%', 
          scale: 1.1,
          scrollTrigger: {
            trigger: sectionRef.current, 
            start: 'top 75%', 
            end: 'bottom top',
            scrub: true, 
            invalidateOnRefresh: true, 

          },
        }
      );

      gsap.fromTo(
        '#titleWorkSection', 
        { x: '0' }, 
        {
          x: '-50%', 
          duration: 40, 
          repeat: -1, 
          ease: "none" 
        }
      );

      gsap.fromTo(
        '#titleServicesSection',
        { x: '-50%' }, 
        {
          x: '0%', 
          duration: 40, 
          repeat: -1, 
          ease: "none" 
        }
      );

      

      return () => {
        lenis.destroy();
      };


   
      

    }

  }, []);


  useEffect(() => {
    if (preloaderLoaded) {
      // Trigger the animation for elements with the `.js-up` class after the preloader completes
      gsap.to('.js-up:first-of-type', {
        y: '0%', 
        duration: 1, 
        ease: "power1.inOut",
        stagger: 0.2,
      });
    }
  }, [preloaderLoaded]);




  


    return (
   <Layout>
      <div id="main">
          <div id="content">
            <div>
            <Hero />
            <div
        id="meSection"
        ref={sectionRef}
        className="w-full flex items-center flex-col justify-center box-content"
      >
        <div  id="mePic" className="overflow-hidden radius-1">
          <img  ref={boxRef} src="/static/me.JPG" alt="me" className="w-full" />
        </div>
      </div>
            </div>

            <div
        id="meText"
        className="flex box-border items-center flex-col justify-center box-content"
      >
        <p className='w-1/3 text-center'>
            Hello Im a creative student with experience in augmented reality, having worked on projects in the field since 2020, 
            now im starting to learn web development 
            trying to create some portfolio.
            Currently, Im studying software engineering where i developed my interest in coding and at the same time trying to learn more by my self.
        </p>

      </div>
      
      
           <div className='grid_project bg-white' id='workSection' ref={workRef}>
           <div className="overflow-hidden relative z-10 title-section">
            <h2 className='js-up f-2em'>Works.</h2>
            </div>
          
            
            <Swiper
            className='gsp_swiper'
            slidesPerView={2}
            freeMode={true}  // Enable free mode
            direction="horizontal"  // Ensure horizontal scrolling
            mousewheel={{
              forceToAxis: true,  // Ensure horizontal scrolling only
            }}
  breakpoints={{
    // when window width is >= 640px
    640: {
      slidesPerView: 3,
    },
    // when window width is >= 768px
    768: {
      slidesPerView: 4,
    },
    // when window width is >= 1024px
    1024: {
      slidesPerView: 4,
    },
    // when window width is >= 1440px
    1440: {
      slidesPerView: 4,
    },
  }}
  onSlideChange={() => console.log('slide change')}
  onSwiper={(swiper) => console.log(swiper)}
  style={{ width: '100%', height: '500px' }}  // Add your custom height or width here
  spaceBetween={20}
>
  {projects.slice(0, 5).map((project, index) => (
    <SwiperSlide key={project.slug}>
      <ProductSpec content={project} setOpened={setOpened} opened={opened} index={index} />
    </SwiperSlide>
  ))}
     <SwiperSlide key={'more'}>
      <div className='extra_slide'>
        <Link to={'/archive'}>
        SEE FULL ARCHIVE
        </Link>
      </div>
    </SwiperSlide>
</Swiper>


           
           </div>



           <div className='services-section bg-white' id='servicesSection' ref={servicesRef}>
          <div className="overflow-hidden relative z-10 title-section">
            <h2 className='js-up f-2em'>Skills.</h2>
          </div>
          <div className='services-section_content'>
            {skills && skills.map((el)=>(
              <SkillElement skill={el} />
            ))}
          </div>
           </div>
          </div>
          </div>
   </Layout>
  )
}


const Hero = () => (
  <div className="flex items-center flex-col justify-center box-content sticky left-0 right-0 top-0" id="hero-index" >
     <div className="overflow-hidden relative z-10">
     <h2 className='js-up f-2em'>Independent Creative Developer</h2>
     </div>
  </div>
)

/*
<div className='title-scroll overflow-hidden'>
            <h2 id='titleWorkSection' className='whitespace-nowrap title-animate'>
            WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK
            WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK
            WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK
            WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK
            WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK
            WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK
            WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK
            WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK WORK
            </h2>
            </div>
            

<div className="overflow-hidden">
      <p>
      Developing awesome digital experiences for business helping them achive their objectives
      </p>
  </div> 
    <div className='me_content'>
           <div className='left'>
            <p>
            Gabriel is a 20-year-old developer with expertise in augmented reality, having worked on projects in the field since 2019. Currently, Gabriel is a full-time student studying software engineering with a strong focus on mathematics. Recently, Gabriel has embarked on a journey into web development and has earned the highest honors in Web Development from their university. With experience in technologies such as React, Django, Next.js, JavaScript, Apollo, HTML, CSS, WordPress, Kirby and more, Gabriel brings a comprehensive understanding of both immersive and web-based development to their work.
            </p>
           </div>
           <div className='right'>
            <img src='/static/me.JPG' />
           </div>
           </div>
            */