export const DOMAIN = process.env.REACT_APP_API_URL;
export const API_PATH = `${DOMAIN}/api/`;
export const UPLOAD_PATH = `${DOMAIN}`;

export const ICONS = {
    paypal: "https://cdn.shopify.com/s/files/1/0283/8996/5877/files/payment_bw_paypal.svg?v=1668460176",
    visa: "https://cdn.shopify.com/s/files/1/0283/8996/5877/files/payment_bw_visa.svg?v=1668460176",
    mastercard: "https://cdn.shopify.com/s/files/1/0283/8996/5877/files/payment_bw_mastercard.svg?v=1668460176",
    amex: "https://cdn.shopify.com/s/files/1/0283/8996/5877/files/payment_bw_amex.svg?v=1668460177",
    gpay : "https://cdn.shopify.com/s/files/1/0283/8996/5877/files/payment_bw_googlepay.svg?v=1668460177",
    applepay: "https://cdn.shopify.com/s/files/1/0283/8996/5877/files/payment_bw_applepay.svg?v=1668460177",

}

export const ModalTypes = {
    CART: 'cart',
    ACCOUNT_LOGIN: 'account-login',
    ACCOUNT_SIGNUP: 'account-signup',
    ACCOUNT_PASSWORD_RESET: 'account-password-reset',
    ACCOUNT: 'account',
    NONE: 'none',
  };

export type ModalType = ModalTypes | null;