import React, { useLayoutEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import { CustomEase } from "gsap/dist/CustomEase";

gsap.registerPlugin(CustomEase);

const Preloader = ({ onComplete }) => {
  const progressBarRef = useRef(null);
  const secondBg = useRef(null);
  const [progress, setProgress] = useState(0);

  useLayoutEffect(() => {
    // Create the custom ease for smooth animation
    CustomEase.create("customEase", "M0,0 C0.85,0 0.15,1 1,1");

    // GSAP Timeline setup for the preloader
    const tl = gsap.timeline({
      onUpdate: () => setProgress(tl.progress() * 100), // Update progress as the animation progresses
      onComplete: () => {
        // Hide the progress bar after the timeline completes
        gsap.to(progressBarRef.current, {
          transformOrigin: "center top",
          y: "-100%", // Move it off the screen
          opacity: 1,
          duration: 1,
          ease: "customEase",
          onComplete: () => {
            progressBarRef.current.style.display = "none"; // Hide the progress bar after animation

            // Animate the preloader text out
            gsap.to('.h1_preloader', {
              transformOrigin: "center top",
              bottom: '-100vh',
              opacity: 1,
              duration: 1,
              ease: "customEase",
            });

            // Shrink the black background
            gsap.to(secondBg.current, {
              transformOrigin: "center top",
              height: '0vh', // Collapse the height
              opacity: 1,
              duration: 1,
              ease: "customEase",
              onComplete: () => {
                secondBg.current.style.display = "none"; // Hide the second background
                gsap.to('.h1_preloader', {
                  display: 'none',
                  duration: 0, // Hide the preloader text
                });
                if (onComplete) onComplete(); // Notify that the preloader has completed
              },
            });
          },
        });
      },
    });

    // Add the loader percentage animation
    tl.to('.h1_loader', { duration: 1.4 });

  }, [onComplete]);

  return (
    <>
      {/* Progress Bar */}
      <div className="full_fixed --index-loader flex justify-center items-center" ref={progressBarRef}>
        <h1 className="h1_loader">{Math.round(progress)}%</h1>
      </div>
      {/* Preloader Background */}
      <div className="full_fixed --index-loader-black flex justify-center items-center" ref={secondBg}>
      </div>
    </>
  );
};

export default Preloader;
