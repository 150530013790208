import React, { useContext } from 'react'
import HeaderContext from '../contexts/HeaderContext'

export default function Notifications() {
    const {notification} = useContext(HeaderContext);
  if (notification.value)return (
    <div className='modal_wrapper_centered'>
      <div className='modal_content'>
        <h1>{notification.value.title}</h1>
        <div className='content_modal'>
        <p>{notification.value.message}</p>
        <span>{notification.value.code}</span>
        </div>
        <button onClick={()=>notification.set(null)}>CLOSE</button>
      </div>
    </div>
  )
}
