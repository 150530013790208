import React from 'react'
import {motion} from 'framer-motion';

export default function Main({id, children, style}) {
  return (
   <main 
   id={id} 
   style={style}
   >
    {children}
   </main>
  )
}
