import { useEffect, useState } from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const MadridTime = () => {
  const [time, setTime] = useState(new Date().toLocaleTimeString('es-ES', { timeZone: 'Europe/Madrid' }));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date().toLocaleTimeString('es-ES', { timeZone: 'Europe/Madrid' }));
    }, 1000);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, []);

  return <span>{time}</span>;
};


export default function Footer() {
  return (
    
    <footer>
      <div className='footer_mail_wrapper'>
      <a href='mailto:contact@gabrisp.com' className='footer_mail'>
      CONTACT@GABRISP.COM
      </a>
      </div>
      <div className='footer_copyright'>
      <div style={{display: 'flex', gap:'5px', flexDirection: 'column'}}>
      <span>© GABRISP</span>
      <span>FULL SITE SOON</span>
      </div>
      <div style={{display: 'flex', gap:'5px', flexDirection: 'column'}}>
      <span>MADRID</span>
      <MadridTime/>
      </div>
      <div style={{display: 'flex', gap:'5px', flexDirection: 'column'}}>
      <Link to="https://instagram.com/gabrisp" target='_blank'>INSTAGRAM</Link>
      </div>
   
      </div>
    </footer>
  )
}


/* <form>
          <input type="email" name="email" placeholder='Email' />
          <button type='submit'>SUBSCRIBE</button>
        </form>
         */