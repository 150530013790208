import React,{ useEffect, useState } from 'react';
import { Router, RouterProvider, useLocation, useNavigate, useRoutes } from 'react-router-dom';
// CONTEXTS
import HeaderContext from './contexts/HeaderContext';
// CSS
import './styles/globals.css';
import './styles/fonts.css';
// Components
import { ModalTypes } from './consts';
import INDEX_PAGE from './pages/Index';
import Archive from './pages/Archive';
import { AnimatePresence, useIsPresent, motion } from 'framer-motion';
import Notifications from './components/Notifications';
import AnimatedCursor from 'react-animated-cursor';
import Preloader from './Layout/Preloader';
import {Helmet} from "react-helmet";

export default function App() {


  const [title, setTitle] = useState("Gabrisp");
  const [prevTitle, setPrevTitle] = useState("Gabrisp");
  const [description, setDescription] = useState("Independent Creative Developer.");
  // Initialize state with the correct type
  const [modal, setModal] = useState(ModalTypes.NONE);

  const [notification, setNotification] = useState("");
  // Resize
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [preloaderLoaded, setPreloaderLoaded] = useState(false);
  // location
  const [location, setLocation] = useState(undefined);


  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
      var r = document.querySelector(':root');
      r.style.setProperty('--width', `${window.innerWidth}px`);
      r.style.setProperty('--height', `${window.innerHeight}px`)
    } 
    window.addEventListener('load', handleResize);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('load', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  // Function to open a modal
  const openModal = (type) => {
    if (!Object.values(ModalTypes).includes(type) && type != ModalTypes.NONE) {
      console.error("Invalid modal type");
      return;
    }
    setModal(type);
   // const newUrl = `${window.location.pathname}?open=${type}`;
  //  window.history.pushState({}, '', newUrl);
    };

  // Function to close the modal
  const closeModal = () => {
    setModal(ModalTypes.NONE);
        // Remove the `?open` param from the URL
        const newUrl = window.location.pathname;
     //   window.history.pushState({}, '', newUrl);
  };


  const headerData = React.useMemo(
    () => ({
      title: {
        value : title,
        set: setTitle
      },
      prevTitle:{
        value: prevTitle,
        set: setPrevTitle,
      },
      description: {
        value : description,
        set: setDescription
      },
      notification:{
        value: notification,
        set: setNotification,
      },
      screen:{
        width,
        height,
      },
      location,
      modal:{
        value:modal,
        open: openModal,
        close: closeModal,
      },
      preloaderLoaded,
  }),
  [title, description, notification, prevTitle, height, width, location, modal, preloaderLoaded]
);


const element = useRoutes([
  {
    path: "/",
    element: <INDEX_PAGE />,
  },
  {
    path: '/archive',
    element: <Archive />
  }
]);

const loc = useLocation();

return (
    <>
      <AnimatedCursor
  color="#fff"
  innerSize={10}
  outerSize={20}
  innerScale={1}
  outerScale={3}
  outerAlpha={0}
  outerStyle={{
    background: 'white',
    mixBlendMode: 'difference'
  }}
/>
    <HeaderContext.Provider value={headerData}>
    <AnimatePresence mode="wait" >
{preloaderLoaded === false && (<div>
  <Preloader onComplete={()=>setPreloaderLoaded(true)} />
</div>)}
      <Notifications />
      <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <description>{description}</description>
            </Helmet>
      {React.cloneElement(element, { key: loc.pathname })}
    </AnimatePresence>
    </HeaderContext.Provider>
    </>
  );

}



