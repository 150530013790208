import React, { useRef, useEffect, useState } from "react";
import { Canvas, useFrame, useThree, useLoader } from "@react-three/fiber";
import * as THREE from "three";
import normalizeWheel from "normalize-wheel"; // Make sure this is installed
import { Functions } from "../functions/functions";
import gsap from "gsap";

const GRID_GAP = 0.1; // Adjust this as needed
const TILE_WIDTH = 2;  // Set the tile width
const TILE_HEIGHT = TILE_WIDTH * (16 / 9);  // Set the height for a 9:16 aspect ratio

const TILE_SPACE_X = TILE_WIDTH + GRID_GAP;  // Horizontal spacing between tiles
const TILE_SPACE_Y = TILE_HEIGHT + GRID_GAP; // Vertical spacing between tiles

const GRID_SIZE_X = TILE_SPACE_X * 3;  // Total grid size for X axis (3 tiles per row)
const GRID_SIZE_Y = TILE_SPACE_Y * 4;  // Total grid size for Y axis (4 tiles per column)
const TOTAL_GRID_SIZE_X = GRID_SIZE_X * 4;  // Total overall grid size in X (4x grid units horizontally)
const TOTAL_GRID_SIZE_Y = GRID_SIZE_Y * 3;  // Total overall grid size in Y

const CORNER_RADIUS = 0.1; // Corner radius for rounded corners

// Function to create rounded rectangle shape
function createRoundedRectShape(width, height, radius) {
  const shape = new THREE.Shape();
  shape.moveTo(-width / 2 + radius, -height / 2);
  shape.lineTo(width / 2 - radius, -height / 2);
  shape.quadraticCurveTo(width / 2, -height / 2, width / 2, -height / 2 + radius);
  shape.lineTo(width / 2, height / 2 - radius);
  shape.quadraticCurveTo(width / 2, height / 2, width / 2 - radius, height / 2);
  shape.lineTo(-width / 2 + radius, height / 2);
  shape.quadraticCurveTo(-width / 2, height / 2, -width / 2, height / 2 - radius);
  shape.lineTo(-width / 2, -height / 2 + radius);
  shape.quadraticCurveTo(-width / 2, -height / 2, -width / 2 + radius, -height / 2);

  return shape;
}

// Function to assign proper UV coordinates to a geometry
function assignUVs(geometry) {
  geometry.computeBoundingBox();
  const { min, max } = geometry.boundingBox;

  const offset = new THREE.Vector2(0 - min.x, 0 - min.y);
  const range = new THREE.Vector2(max.x - min.x, max.y - min.y);

  geometry.attributes.position.array.forEach((_, i) => {
    const x = geometry.attributes.position.getX(i);
    const y = geometry.attributes.position.getY(i);

    const u = (x + offset.x) / range.x;
    const v = (y + offset.y) / range.y;

    geometry.attributes.uv.setXY(i, u, v);
  });
}

// Helper function to smoothly interpolate opacity
function animateOpacity(material, targetOpacity, duration = 0.5) {
  const initialOpacity = material.opacity;
  let startTime = null;

  function animate(time) {
    if (!startTime) startTime = time;
    const elapsedTime = (time - startTime) / 1000;
    const t = Math.min(elapsedTime / duration, 1);

    material.opacity = THREE.MathUtils.lerp(initialOpacity, targetOpacity, t);
    material.needsUpdate = true;

    if (t < 1) {
      requestAnimationFrame(animate);
    }
  }

  requestAnimationFrame(animate);
}

// Clone groups
const TILE_GROUPS = [
  { pos: [-GRID_SIZE_X, GRID_SIZE_Y, 0] },
  { pos: [0, GRID_SIZE_Y, 0] },
  { pos: [GRID_SIZE_X, GRID_SIZE_Y, 0] },
  { pos: [GRID_SIZE_X * 2, GRID_SIZE_Y, 0] },  // New 4th column
  { pos: [-GRID_SIZE_X, 0, 0] },
  { pos: [0, 0, 0] },
  { pos: [GRID_SIZE_X, 0, 0] },
  { pos: [GRID_SIZE_X * 2, 0, 0] },  // New 4th column
  { pos: [-GRID_SIZE_X, -GRID_SIZE_Y, 0] },
  { pos: [0, -GRID_SIZE_Y, 0] },
  { pos: [GRID_SIZE_X, -GRID_SIZE_Y, 0] },
  { pos: [GRID_SIZE_X * 2, -GRID_SIZE_Y, 0] },  // New 4th column
];


// Image Tile component
const ImageTile = ({ position, image, onClick, isDisabled, isClicked }) => {
  const texture = useLoader(THREE.TextureLoader, image); // Load the texture
  const materialRef = useRef();
  const meshRef = useRef(); // Reference to the mesh
  const [isTextureReady, setIsTextureReady] = useState(false); // Track when texture is loaded
  const [opacity, setOpacity] = useState(0); // Track opacity for fade-in effect

  // Create the geometry using the rounded rectangle shape
  const roundedRectShape = createRoundedRectShape(TILE_WIDTH, TILE_HEIGHT, CORNER_RADIUS);
  const geometry = new THREE.ShapeGeometry(roundedRectShape);

  // Assign UV coordinates to the geometry
  assignUVs(geometry);

  // Adjust texture aspect ratio and repeat for cover effect
  useEffect(() => {
    if (texture) {
      // Calculate aspect ratios for cover effect
      const tileAspectRatio = TILE_WIDTH / TILE_HEIGHT;
      const textureAspectRatio = texture.image.width / texture.image.height;

      if (textureAspectRatio > tileAspectRatio) {
        // If texture is wider, adjust the width (repeat less on X axis)
        texture.repeat.set(tileAspectRatio / textureAspectRatio, 1);
      } else {
        // If texture is taller, adjust the height (repeat less on Y axis)
        texture.repeat.set(1, textureAspectRatio / tileAspectRatio);
      }

      // Set the texture to cover the entire surface and prevent distortion
      texture.center.set(0.5, 0.5);
      texture.offset.set(0, 0);
      texture.wrapS = THREE.RepeatWrapping;
      texture.wrapT = THREE.RepeatWrapping;

      // Once texture is ready, trigger opacity animation
      setIsTextureReady(true);
    }
  }, [texture]);

  // Handle the opacity animation when the texture is ready
  useEffect(() => {
    if (isTextureReady) {
      gsap.to(materialRef.current, { opacity: 1, duration: 1, ease: "power2.out" });
    }
  }, [isTextureReady]);

  // Handle click-based opacity changes
  useEffect(() => {
    if (materialRef.current) {
      materialRef.current.opacity = isClicked ? 0 : 1; // Set opacity to 0 when clicked
    }
  }, [isClicked]);
  if (!isTextureReady) {
    return null; // Render nothing until texture is ready
  }
  
  return (
    <mesh ref={meshRef} position={position} onClick={!isDisabled ? onClick : undefined}>
      <primitive object={geometry} />
      <meshBasicMaterial
        ref={materialRef}
        map={texture} // Map the texture to the material
        transparent
        opacity={opacity} // Start with opacity 0
        color={0xffffff} // Set base material to white
      />
    </mesh>
  );
};

const VideoTile = ({ position, videoUrl, onClick, isDisabled, isClicked }) => {
  const videoRef = useRef();
  const textureRef = useRef();
  const materialRef = useRef();
  const meshRef = useRef(); // Ref for the mesh

  const [isVideoReady, setIsVideoReady] = useState(false); // State to track if video is ready

  const roundedRectShape = createRoundedRectShape(TILE_WIDTH, TILE_HEIGHT, CORNER_RADIUS);
  const geometry = new THREE.ShapeGeometry(roundedRectShape);
  assignUVs(geometry);

  // Set up video and texture when component mounts
  useEffect(() => {
    const video = document.createElement("video");
    video.src = videoUrl;
    video.crossOrigin = "anonymous";
    video.loop = true;
    video.muted = true;
    video.playsInline = true;
    video.autoplay = false;  // Automatically start playing
    video.setAttribute("playsinline", "true");

    const texture = new THREE.VideoTexture(video);
    texture.encoding = THREE.SRGBColorSpace; // Ensure correct color space
    texture.minFilter = THREE.LinearFilter;
    texture.magFilter = THREE.LinearFilter;
    texture.format = THREE.RGBAFormat;
    texture.premultipliedAlpha = false; // Ensure correct alpha blending

    // Set the refs for texture and video
    textureRef.current = texture;
    videoRef.current = video;

    // When the video metadata is loaded, trigger the opacity animation and play the video
    video.onloadeddata = () => {
      setIsVideoReady(true); // Set video as ready
      gsap.to(materialRef.current, { opacity: 1, duration: 1, ease: "power2.out" });

      // Replace the material with video texture
      if (meshRef.current) {
        meshRef.current.material.map = textureRef.current; // Apply video texture
        meshRef.current.material.needsUpdate = true; // Force material update
      }
    };

    // Cleanup when component unmounts
    return () => {
      video.pause();
      video.src = "";
      videoRef.current = null;
      texture.dispose();
    };
  }, [videoUrl]);

  // Handle play/pause based on the clicked state
  useEffect(() => {
    if (isClicked && videoRef.current) {
      videoRef.current.play(); // Play video when clicked
    } else if (videoRef.current) {
      videoRef.current.pause(); // Pause video when not clicked
      videoRef.current.currentTime = 0; // Reset time to the beginning
    }
  }, [isClicked]);

  return (
    <mesh ref={meshRef} position={position} onClick={!isDisabled ? onClick : undefined}>
      <primitive object={geometry} />
      <meshBasicMaterial
        ref={materialRef}
        map={textureRef.current} // Map the video texture to the material
        transparent
        opacity={0} // Start with 0 opacity and animate to 1
        toneMapped={false} // Disable tone mapping to preserve correct colors
        color={0xffffff} // Ensure the base color is white
      />
    </mesh>
  );
};





// Tile Group component
const TileGroup = ({ position, groupRef, onClick, clickedIndex, isDisabled, data, isAnyTileClicked }) => { // Add isAnyTileClicked as a prop
  const groupWidth = TILE_SPACE_X * 3 - GRID_GAP;
  const groupHeight = TILE_SPACE_Y * 3 - GRID_GAP;

  const createBorderGeometry = () => {
    const borderGeometry = new THREE.BufferGeometry();
    const vertices = new Float32Array([
      -groupWidth / 2, -groupHeight / 2, 0,
      groupWidth / 2, -groupHeight / 2, 0,
      groupWidth / 2, -groupHeight / 2, 0,
      groupWidth / 2, groupHeight / 2, 0,
      groupWidth / 2, groupHeight / 2, 0,
      -groupWidth / 2, groupHeight / 2, 0,
      -groupWidth / 2, groupHeight / 2, 0,
      -groupWidth / 2, -groupHeight / 2, 0,
    ]);
    borderGeometry.setAttribute('position', new THREE.BufferAttribute(vertices, 3));
    return borderGeometry;
  };

  const borderMaterial = new THREE.LineBasicMaterial({ color: 0xffffff });

  return (
    <group ref={groupRef} position={position}>
      {data.map((tile, i) => (
        <>
          {tile?.mediaCollection?.items[0].contentType.includes("image") && (
            <ImageTile
              key={i}
              position={[
                (i % 3) * TILE_SPACE_X,
                -(Math.floor(i / 3) * TILE_SPACE_Y),
                0
              ]}
              obj={tile}
              image={tile.mediaCollection.items[0].url}
              onClick={(e) => onClick(i, e.object, tile)}
              isClicked={clickedIndex === i}
              isDisabled={isDisabled && clickedIndex !== i}
              isAnyTileClicked={isAnyTileClicked}  // Pass isAnyTileClicked to ImageTile
            />
          )}
          {tile?.mediaCollection?.items[0].contentType.includes("video") && (
            <VideoTile
              key={i}
              position={[
                (i % 3) * TILE_SPACE_X,
                -(Math.floor(i / 3) * TILE_SPACE_Y),
                0
              ]}
              obj={tile}
              videoUrl={tile.mediaCollection.items[0].url}
              onClick={(e) => onClick(i, e.object, tile)}
              isClicked={clickedIndex === i}
              isDisabled={isDisabled && clickedIndex !== i}
              isAnyTileClicked={isAnyTileClicked}  // Pass isAnyTileClicked to VideoTile
            />
          )}
        </>
      ))}
    </group>
  );
};


const Scene = ({setTileContent}) => {

  
  const scrollRef = useRef({
    current: { x: 0, y: 0 },
    target: { x: 0, y: 0 },
    ease: 0.05,
    scale: 0.02,
    last: { x: 0, y: 0 },
  });

  const { gl, camera, scene } = useThree();
  
  // Initialize enough groupRefs (we need original + duplicates for left, right, top, bottom)
  const groupRefs = useRef([...Array(TILE_GROUPS.length * 5)].map(() => React.createRef()));

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [clickedTile, setClickedTile] = useState(null);
  const [isTileClicked, setIsTileClicked] = useState(false);
  const [isAnyTileClicked, setIsAnyTileClicked] = useState(false);
  const [targetCameraPos, setTargetCameraPos] = useState(camera.position.clone());
  const [initialCameraPos, setInitialCameraPos] = useState(camera.position.clone());
  const [data, setData] = useState([]);

  useEffect(() => {
    Functions.projects()
      .then((fetchedData) => {
        const number_of_columns = 3;

        const transformedData = fetchedData.map((item, index) => ({
          ...item,
          posX: (index % number_of_columns) * TILE_SPACE_X,
          posY: -(Math.floor(index / number_of_columns) * TILE_SPACE_Y),
        }));
        setData(transformedData);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  useEffect(() => {
    const handleWheel = (e) => {
      if (!isTileClicked) {
        e.preventDefault();
        const normalized = normalizeWheel(e);
        scrollRef.current.target.y += normalized.pixelY * scrollRef.current.scale;
        scrollRef.current.target.x -= normalized.pixelX * scrollRef.current.scale;
      }
    };

    gl.domElement.addEventListener("wheel", handleWheel);

    return () => {
      gl.domElement.removeEventListener("wheel", handleWheel);
    };
  }, [gl, isTileClicked]);

  useEffect(() => {
    const handleMouseDown = (e) => {
      setIsDragging(true);
      setDragStart({ x: e.clientX, y: e.clientY });
    };

    const handleMouseMove = (e) => {
      if (isDragging && !isTileClicked) {
        const deltaX = e.clientX - dragStart.x;
        const deltaY = e.clientY - dragStart.y;

        scrollRef.current.target.x += deltaX * 0.01;
        scrollRef.current.target.y -= deltaY * 0.01;

        setDragStart({ x: e.clientX, y: e.clientY });
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    gl.domElement.addEventListener("mousedown", handleMouseDown);
    gl.domElement.addEventListener("mousemove", handleMouseMove);
    gl.domElement.addEventListener("mouseup", handleMouseUp);

    return () => {
      gl.domElement.removeEventListener("mousedown", handleMouseDown);
      gl.domElement.removeEventListener("mousemove", handleMouseMove);
      gl.domElement.removeEventListener("mouseup", handleMouseUp);
    };

  }, [gl, isDragging, dragStart, isTileClicked]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.intersections || e.intersections.length === 0) {
        scene.traverse((child) => {
          if (child.isMesh) {
            animateOpacity(child.material, 1, 0.5);
          }
        });
        setIsTileClicked(false);
        setClickedTile(null);
        setTargetCameraPos(initialCameraPos.clone());
        setIsAnyTileClicked(false);  // Reset the state when no tile is clicked
        setTileContent(null);
      }
    };

    gl.domElement.addEventListener("pointerdown", handleClickOutside);

    return () => {
      gl.domElement.removeEventListener("pointerdown", handleClickOutside);
    };
  }, [gl, scene, initialCameraPos]);

  useFrame(() => {
    if (!isTileClicked) {
      const { current, target, ease } = scrollRef.current;

      // Update both horizontal and vertical scroll positions
      current.x = lerp(current.x, target.x, ease);
      current.y = lerp(current.y, target.y, ease);
  
      groupRefs.current.forEach((groupRef, i) => {
        const group = groupRef?.current;
        if (group) {
          // Calculate the offsets for both X and Y axes with wrapping
          let offsetX = (TILE_GROUPS[i % TILE_GROUPS.length].pos[0] + current.x) % TOTAL_GRID_SIZE_X;
          let offsetY = (TILE_GROUPS[i % TILE_GROUPS.length].pos[1] + current.y) % TOTAL_GRID_SIZE_Y;
  
          // Handle wrapping horizontally (left-right)
          if (offsetX < -TOTAL_GRID_SIZE_X / 2) {
            offsetX += TOTAL_GRID_SIZE_X;
          } else if (offsetX > TOTAL_GRID_SIZE_X / 2) {
            offsetX -= TOTAL_GRID_SIZE_X;
          }
  
          // Handle wrapping vertically (top-bottom)
          if (offsetY < -TOTAL_GRID_SIZE_Y / 2) {
            offsetY += TOTAL_GRID_SIZE_Y;
          } else if (offsetY > TOTAL_GRID_SIZE_Y / 2) {
            offsetY -= TOTAL_GRID_SIZE_Y;
          }
  
          // Apply the wrapped positions to the current tile group
          group.position.set(offsetX, offsetY, TILE_GROUPS[i % TILE_GROUPS.length].pos[2]);
  
          // Handle the adjacent tile groups on left, right, top, bottom
  
          // Group to the left
          if (groupRefs.current[i + 1]?.current) {
            const leftGroupOffsetX = offsetX - TOTAL_GRID_SIZE_X;
            groupRefs.current[i + 1].current.position.set(leftGroupOffsetX, offsetY, TILE_GROUPS[i % TILE_GROUPS.length].pos[2]);
          }
  
          // Group to the right
          if (groupRefs.current[i + 2]?.current) {
            const rightGroupOffsetX = offsetX + TOTAL_GRID_SIZE_X;
            groupRefs.current[i + 2].current.position.set(rightGroupOffsetX, offsetY, TILE_GROUPS[i % TILE_GROUPS.length].pos[2]);
          }
  
          // Group above
          if (groupRefs.current[i + 3]?.current) {
            const topGroupOffsetY = offsetY + TOTAL_GRID_SIZE_Y;
            groupRefs.current[i + 3].current.position.set(offsetX, topGroupOffsetY, TILE_GROUPS[i % TILE_GROUPS.length].pos[2]);
          }
  
          // Group below
          if (groupRefs.current[i + 4]?.current) {
            const bottomGroupOffsetY = offsetY - TOTAL_GRID_SIZE_Y;
            groupRefs.current[i + 4].current.position.set(offsetX, bottomGroupOffsetY, TILE_GROUPS[i % TILE_GROUPS.length].pos[2]);
          }

          
        }
      });
    }
  
    // Smoothly move the camera to the target position (if any tile is clicked)
    camera.position.lerp(targetCameraPos, 0.1);
  });
  
  const handleTileClick = (tileIndex, tileObject, obj) => {
    setClickedTile(tileIndex);
    setIsTileClicked(true);
    setIsAnyTileClicked(true); // Set to true when any tile is clicked
    console.log(obj);
    setTileContent(obj);
    scene.traverse((child) => {
      if (child.isMesh) {
        animateOpacity(child.material, child === tileObject ? 1 : 0, 0.5);
      }
    });

    setInitialCameraPos(camera.position.clone());

    const worldPosition = new THREE.Vector3();
    tileObject.getWorldPosition(worldPosition);

    const newCameraPos = new THREE.Vector3(worldPosition.x, worldPosition.y, camera.position.z);
    setTargetCameraPos(newCameraPos);
  };

  return (
    <>
      {TILE_GROUPS.map((group, i) => (
        <TileGroup
          key={i}
          position={group.pos}
          groupRef={groupRefs.current[i]}
          onClick={handleTileClick}
          clickedIndex={clickedTile}
          isDisabled={isTileClicked}
          data={data}
          isAnyTileClicked={isAnyTileClicked}  // Pass this state to the TileGroup
        />
      ))}
    </>
  );
  
};


// Helper function for linear interpolation
function lerp(start, end, amount) {
  return start * (1 - amount) + end * amount;
}

// InfiniteImageGrid component
function InfiniteImageGrid() {
  const [titleVisible, setTitleVisible] = useState(null);
  return (
   <>
      <div className={`h-screen w-screen absolute data-canvas title ${titleVisible === null ? '' : 'visible'}`}>
        <div 
        style={{width:"235px", height:"400px" }} 
        onMouseOver={(e)=>{
          console.log(e);
        }} >

        </div>
      <h3>{titleVisible && titleVisible.title && (
        titleVisible.title
      )}</h3>
   </div>
   <Canvas style={{ width: '100vw', height: '100vh', background: 'white' }}>
      <Scene setTileContent={setTitleVisible} />
    </Canvas>
    
    </>
  );
}

export default InfiniteImageGrid;