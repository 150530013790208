import React, { useEffect, useState } from 'react'

function setCookieF(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default function CookieNotification() {
  const [cookie, setCookie] = useState(false);
  useEffect(() => {
   const cookieF = getCookie("gabrisp_cookie");
   console.log(cookieF);
   if (cookieF === 'true' ){
    setCookie(cookieF);
   }else{
    setCookie(false);
   }
  }, [])
  
  if(cookie === false) return (
    <div id='message'>
      This site use cookies
      <button className='close_cookies' onClick={()=>{
        setCookieF("gabrisp_cookie", "true", 10)
        setCookie(true);
      }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><title>Close</title> <g stroke-width="1.5" fill="none" stroke="#000000" stroke-linecap="round" stroke-linejoin="round"><line x1="1.5" y1="1.5" x2="10.5" y2="10.5"></line> <line x1="10.5" y1="1.5" x2="1.5" y2="10.5"></line></g></svg>
      </button>
    </div>
  )
}
