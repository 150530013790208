import React, { useContext, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import HeaderContext from '../../contexts/HeaderContext';


export default function Header() {
    const {  modal } = useContext(HeaderContext);
    const navigate = useNavigate();
    useEffect(() => {
        modal.close()
    }, [])
    
    
    return (
        <header className='header'>
           <div className='header_wrapper'>
            <div className='header_logo  column-header'>
            <Link to={'/'} className='_logo'>
            GABRISP
            </Link>
            </div>
            <Link to={'/archive'} className='column-header'>
            ARCHIVE
            </Link>
           </div>
            
        </header>
      )
    
}
