
export var   Functions = {
  projects: getAllProjects,
  skills: getAllSkills,
}



export async function getAllSkills(
  limit = 100,
  isDraftMode = false
) {
  const response = await fetchGraphQL(
    `query GetSkillsCollection(
  $skip: Int = 0,
  $limit: Int = ${limit},
  $preview: Boolean,
  $locale: String,
  $where: SkillsFilter,
  $order: [SkillsOrder]
) {
  skillsCollection(
    skip: $skip,
    limit: $limit,
    preview: $preview,
    locale: $locale,
    where: $where,
    order: $order
  ) {
    total
    skip
    limit
    items {
      contentfulMetadata {
        tags {
          id
          name
        }
      }
      title(locale: $locale)
      icon {
        url
        title
        description
        contentType
        fileName
      }
    }
  }
}

`,
    isDraftMode
  );

  // Check if the response has the expected structure
  if (!response || !response.data || !response.data.skillsCollection) {
    console.error("Unexpected response structure:", response);
    return [];
  }

  const projects = response.data.skillsCollection.items;

  console.log(projects);
  return projects;
}


export async function getAllProjects(
  limit = 100,
  isDraftMode = false
) {
  const response = await fetchGraphQL(
    `query GetProjectCollection(
$skip: Int = 0,
$limit: Int = ${limit},
$preview: Boolean,
$locale: String,
$where: ProjectFilter,
$order: [ProjectOrder]
) {
projectCollection(
  skip: $skip,
  limit: $limit,
  preview: $preview,
  locale: $locale,
  where: $where,
  order: $order
) {
  total
  skip
  limit
  items {
    contentfulMetadata {
      tags {
        id
        name
      }
    }
    _id
    title(locale: $locale)
    date
    mediaCollection {
      items {
        title
        url
        contentType
        fileName
      }
    }
    url
    slug
  }
}
}
`,
    isDraftMode
  );

  // Check if the response has the expected structure
  if (!response || !response.data || !response.data.projectCollection) {
    console.error("Unexpected response structure:", response);
    return [];
  }

  const projects = response.data.projectCollection.items;

  console.log(projects);
  return projects;
}


  async function fetchGraphQL(query, preview = false) {
    return fetch(
     `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Switch the Bearer token depending on whether the fetch is supposed to retrieve live
          // Contentful content or draft content
          Authorization: `Bearer ${
            preview
              ? process.env.REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN
              : process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN
          }`,
        },
        body: JSON.stringify({ query }),
        // Associate all fetches for articles with an "articles" cache tag so content can
        // be revalidated or updated from Contentful on publish
       // next: { tags: ["articles"] },
      }
    ).then((response) => response.json());
}