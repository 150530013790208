import React from 'react'
import Main from './Main'
import Header from './Header'
import Footer from './Footer/Footer'
import {  motion, useIsPresent } from 'framer-motion'
import { useLocation } from 'react-router-dom'
import CookieNotification from '../components/CookieNotification'

export default function Layout(props) {
  const isPresent = useIsPresent();

  return (
    <>
   
      <CookieNotification />
      <Header />
      <motion.div
    initial={{opacity: 0}}
    animate={{opacity: 1}}
    exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <Main id={props.id} style={props.style}>
        {props.children}
      </Main>
     {(props.footer === undefined || props.footer === true) &&( <Footer />)}
    </motion.div>
    </>
  )
}

