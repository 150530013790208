import React, { useContext } from 'react'
import Layout from '../Layout/Layout'
import { useState, useEffect } from 'react';
import { Functions } from '../functions/functions';
import InfiniteImageGrid from '../components/InfiniteImagesGrid';
import HeaderContext from '../contexts/HeaderContext';

export default function Archive() {

    const [images, setImages] = useState([]);
    const {title} = useContext(HeaderContext);

    useEffect(() => {

      title.set("Archive | Gabrisp");
    
    }, [])
  
    return (
      <Layout footer={false}>
          <InfiniteImageGrid  />
    </Layout>
    );
  };

